var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c("div", { staticClass: "order-content" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "h-bg" }),
        _c(
          "div",
          {
            staticClass: "user-info radius flex",
            on: { click: _vm.toDeliveryAddress },
          },
          [
            _c("div", { staticClass: "address" }, [
              _vm.addressInfo.isDefault ? _c("i", [_vm._v("默认")]) : _vm._e(),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.addressInfo.provenceName) +
                    _vm._s(_vm.addressInfo.cityName) +
                    _vm._s(_vm.addressInfo.countyName)
                ),
              ]),
            ]),
            _c("p", { staticClass: "add-de" }, [
              _vm._v(" " + _vm._s(_vm.addressInfo.address) + " "),
            ]),
            _c("span", { staticClass: "ui" }, [
              _vm._v(
                _vm._s(_vm.addressInfo.name) +
                  " " +
                  _vm._s(_vm.addressInfo.mobilePhone)
              ),
            ]),
            !_vm.addressInfo.name
              ? _c("span", { staticClass: "add-de" }, [
                  _vm._v("请选择收货地址"),
                ])
              : _vm._e(),
            _c("span", { staticClass: "to" }),
          ]
        ),
      ]),
      _c("div", { staticClass: "detail" }, [
        _c("div", { staticClass: "order radius mt-8" }, [
          _vm._v(" 处方ID：" + _vm._s(_vm.prescriptionInfo.orderId) + " "),
        ]),
        _c("div", { staticClass: "prescription radius mt-8" }, [
          _c("div", { staticClass: "top flex f-jc-sb" }, [
            _c("div", { staticClass: "left" }, [
              _c("p", [_vm._v("互联网诊疗平台处方笺")]),
              _c("span", [
                _vm._v("日期：" + _vm._s(_vm.prescriptionInfo.createdAt)),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [_vm._v("普通药品处方")]),
          ]),
          _c("div", { staticClass: "bottom" }, [
            _c("span", { staticClass: "mr" }, [
              _c("i", [_vm._v("姓名")]),
              _c("i", [_vm._v(_vm._s(_vm.prescriptionInfo.patientName))]),
            ]),
            _c("span", { staticClass: "mr" }, [
              _c("i", [_vm._v("性别")]),
              _c("i", [
                _vm._v(_vm._s(_vm.prescriptionInfo.gender === 1 ? "男" : "女")),
              ]),
            ]),
            _c("span", [
              _c("i", [_vm._v("年龄")]),
              _c("i", [_vm._v(_vm._s(_vm.prescriptionInfo.age))]),
            ]),
            _c("span", [
              _c("i", [_vm._v("科室")]),
              _c("i", [_vm._v(_vm._s(_vm.prescriptionInfo.deptName))]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "zd radius mt-8" }, [
          _c("p", { staticClass: "title" }, [_vm._v("诊断")]),
          _c("div", { staticClass: "flex" }, [
            _vm.prescriptionInfo.xyzd
              ? _c("span", [
                  _vm._v("西医诊断：" + _vm._s(_vm.prescriptionInfo.xyzd)),
                ])
              : _vm._e(),
            _c("span", [
              _vm._v("中医诊断：" + _vm._s(_vm.prescriptionInfo.zyzd)),
            ]),
            _c("span", [
              _vm._v("中医证型：" + _vm._s(_vm.prescriptionInfo.zybz)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "kf radius mt-8" }, [
          _c("p", { staticClass: "title" }, [_vm._v("开方")]),
          _c("span", [
            _vm._v("服用方式：" + _vm._s(_vm.prescriptionInfo.fyff)),
          ]),
          _c("span", [
            _vm._v("剂型选择：" + _vm._s(_vm.prescriptionInfo.dosageFormName)),
          ]),
          _vm.prescriptionInfo.dosageForm !== "2"
            ? _c("span", [
                _vm._v(
                  "代煎设置：" + _vm._s(_vm.prescriptionInfo.dosageFormSubName)
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "detail" }, [
            _vm._v(" " + _vm._s(_vm.prescriptionInfo._rp)),
            _c("br"),
            _vm._v(
              " 共" +
                _vm._s(_vm.prescriptionInfo.jiliang) +
                "剂，每日" +
                _vm._s(_vm.prescriptionInfo.jlPerDay) +
                "剂，1剂分" +
                _vm._s(_vm.prescriptionInfo.times) +
                "次服用 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "yz radius mt-8" }, [
          _c("p", { staticClass: "title" }, [_vm._v("医嘱")]),
          _c("span", [_vm._v(_vm._s(_vm.prescriptionInfo.yz))]),
        ]),
        _c("div", { staticClass: "fy radius mt-8" }, [
          _vm.prescriptionInfo.outpatientCare
            ? _c("div", { staticClass: "flex f-jc-sb" }, [
                _c("span", [_vm._v("诊费")]),
                _c("i", [
                  _vm._v("￥ " + _vm._s(_vm.prescriptionInfo.outpatientCare)),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "flex f-jc-sb" }, [
            _c("span", [_vm._v("药费")]),
            _c("i", [
              _vm._v("￥ " + _vm._s(_vm.prescriptionInfo.medicinePrice)),
            ]),
          ]),
          _c("div", { staticClass: "flex f-jc-sb" }, [
            _c("span", [_vm._v("加工费")]),
            _c("i", [
              _vm._v("￥ " + _vm._s(_vm.prescriptionInfo.processingFee)),
            ]),
          ]),
          _vm.prescriptionInfo.logisticsFee
            ? _c("div", { staticClass: "flex f-jc-sb" }, [
                _c("span", [_vm._v("运费")]),
                _c("i", [
                  _vm._v("￥ " + _vm._s(_vm.prescriptionInfo.logisticsFee)),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "pay flex f-jc-sb f-ai-c" }, [
      _c("span", [
        _vm._v(" ￥"),
        _c("i", [
          _vm._v(
            _vm._s(_vm.prescriptionInfo.totalFee.toString().split(".")[0])
          ),
        ]),
        _vm.prescriptionInfo.totalFee.toString().split(".")[1]
          ? _c("i", [
              _vm._v(
                "." +
                  _vm._s(_vm.prescriptionInfo.totalFee.toString().split(".")[1])
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "pay-btn" },
        [
          _c("s-button", {
            attrs: { btnText: "支付" },
            on: { "btn-click": _vm.pay },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }