<template>
	<div class="wrap">
		<div class="order-content">
			<div class="header">
				<div class="h-bg"></div>
				<div class="user-info radius flex" @click="toDeliveryAddress">
					<div class="address">
						<i v-if="addressInfo.isDefault">默认</i>
						<span>{{addressInfo.provenceName}}{{addressInfo.cityName}}{{addressInfo.countyName}}</span>
					</div>
					<p class="add-de">
						{{addressInfo.address}}
					</p>
					<span class="ui">{{addressInfo.name}}  {{addressInfo.mobilePhone}}</span>
					<span class="add-de" v-if="!addressInfo.name">请选择收货地址</span>
					<span class="to"></span>
				</div>
			</div>
			<div class="detail">
				<div class="order radius mt-8">
					处方ID：{{prescriptionInfo.orderId}}
				</div>
				<div class="prescription radius mt-8">
					<div class="top flex f-jc-sb">
						<div class="left">
							<p>互联网诊疗平台处方笺</p>
							<span>日期：{{prescriptionInfo.createdAt}}</span>
						</div>
						<div class="right">普通药品处方</div>
					</div>
					<div class="bottom">
						<span class="mr">
							<i>姓名</i>
							<i>{{prescriptionInfo.patientName}}</i>
						</span>
						<span class="mr">
							<i>性别</i>
							<i>{{prescriptionInfo.gender === 1 ? '男' : '女'}}</i>
						</span>
						<span>
							<i>年龄</i>
							<i>{{prescriptionInfo.age}}</i>
						</span>
						<span>
							<i>科室</i>
							<i>{{prescriptionInfo.deptName}}</i>
						</span>
					</div>
				</div>
				<div class="zd radius mt-8">
					<p class="title">诊断</p>
					<div class="flex">
						<span v-if="prescriptionInfo.xyzd">西医诊断：{{prescriptionInfo.xyzd}}</span>
						<span>中医诊断：{{prescriptionInfo.zyzd}}</span>
						<span>中医证型：{{prescriptionInfo.zybz}}</span>
					</div>
				</div>
				<div class="kf radius mt-8">
					<p class="title">开方</p>
					<span>服用方式：{{prescriptionInfo.fyff}}</span>
					<span>剂型选择：{{prescriptionInfo.dosageFormName}}</span>
					<span v-if="prescriptionInfo.dosageForm !== '2'">代煎设置：{{prescriptionInfo.dosageFormSubName}}</span>
					<div class="detail">
						{{prescriptionInfo._rp}}<br/>
						共{{prescriptionInfo.jiliang}}剂，每日{{prescriptionInfo.jlPerDay}}剂，1剂分{{prescriptionInfo.times}}次服用
					</div>
				</div>
				<div class="yz radius mt-8">
					<p class="title">医嘱</p>
					<span>{{prescriptionInfo.yz}}</span>
				</div>
				<div class="fy radius mt-8">
					<div class="flex f-jc-sb" v-if="prescriptionInfo.outpatientCare">
						<span>诊费</span>
						<i>￥ {{prescriptionInfo.outpatientCare}}</i>
					</div>
					<div class="flex f-jc-sb">
						<span>药费</span>
						<i>￥ {{prescriptionInfo.medicinePrice}}</i>
					</div>
					<div class="flex f-jc-sb">
						<span>加工费</span>
						<i>￥ {{prescriptionInfo.processingFee}}</i>
					</div>
					<div class="flex f-jc-sb" v-if="prescriptionInfo.logisticsFee">
						<span>运费</span>
						<i>￥ {{prescriptionInfo.logisticsFee}}</i>
					</div>
				</div>
			</div>
		</div>
		<div class="pay flex f-jc-sb f-ai-c">
			<span>
				￥<i>{{(prescriptionInfo.totalFee).toString().split('.')[0]}}</i>
				<i v-if="(prescriptionInfo.totalFee).toString().split('.')[1]">.{{(prescriptionInfo.totalFee).toString().split('.')[1]}}</i>
			</span>
			<div class="pay-btn">
				<s-button btnText="支付" @btn-click="pay"></s-button>
			</div>
		</div>
	</div>
</template>
<script>
import sButton from '@/components/Button';
import { mapGetters } from 'vuex';
import { defaultAddress, prescriptionDetail, appointmentAdd } from '@/request/api/user';
import { dateFormat } from '@/utils/time';
import { ceil } from '@/utils';

export default {
	data () {
		return {
			addressInfo: {
				address: '',
				cityName: '',
				countyName: '',
				mobilePhone: '',
				name: '',
				provenceName: '',
				userId: 0,
				id: 0
			},
			prescriptionInfo: {
				totalFee: ''
			},
			appointmentId: ''
		};
	},
	computed: {
		...mapGetters({
			addressData: 'getAddressInfo'
		})
	},
	components: { sButton },
	created () {
		if (this.addressData && this.addressData.id && this.$route.query.isChanged) {
			this.addressInfo = { ...this.addressData };
		} else {
			this.getDefaultAddress();
		}
		this.getPrescriptionDetail();
	},
	mounted () {
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener('popstate', this.onBack, false);
		}
		// window.addEventListener('popstate', this.onBack, false);
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	methods: {
		onBack () {
			// this.showGoBack = true;
			// this.$root.go('/user-center');
			this.$root.go(-1);
			// this.$root.go(`/prescription-detail?id=${this.prescriptionInfo.id}`);
		},
		getDefaultAddress () {
			defaultAddress().then(data => {
				if (data && data === 'retry') {
					this.getDefaultAddress();
				} else if (data) {
					this.addressInfo = { ...data, isDefault: true };
					if (this.addressData && this.addressData.id && this.$route.query.isChanged) this.addressInfo = { ...this.addressData };
				}
			});
		},
		getPrescriptionDetail () {
			prescriptionDetail({ id: this.$route.query.id }).then(data => {
				// console.log('prescriptionDetail======', data);
				if (data && data === 'retry') {
					this.getPrescriptionDetail();
				} else if (data) {
					const _rp = [];
					data.rp = JSON.parse(data.rp);
					data.rp.forEach(r => {
						let name = `${r.name}`;
						if (data.status === 2 || data.status === 3 || data.status === 5 || (data.status === 1 && data.payFirst === 0)) {
							name += `${r.count}${r.unit}`;
						}
						_rp.push(`${name}`); // .split('/')[1]
					});
					data._rp = _rp.join(' ');
					data.totalFee = ceil(+data.totalFee, 2)
					this.prescriptionInfo = { ...data, createdAt: dateFormat(new Date(data.createdAt), 'yyyy-MM-dd') };
				}
			});
		},
		pay () {
			// const type = 'Prescription';
			if (this.prescriptionInfo.appointmentId) {
				this.$root.go(`/appointment-detail?id=${this.prescriptionInfo.appointmentId}`);
			} else {
				if (!this.addressInfo.id) {
					this.$toast('请选择收获地址');
				} else {
					const reqData = {
						uuid: this.prescriptionInfo.uuid,
						orderId: this.prescriptionInfo.orderId,
						deliveryAddressId: this.addressInfo.id,
						prescriptionId: this.prescriptionInfo.id
					};
					appointmentAdd(reqData).then(data => {
						if (data) {
							this.appointmentId = data.id;
							this.gotoPay();
						} else {
							// this.$root.go({
							// path: '/order-list'
							// });
						}
					});
				}
			}
		},
		gotoPay () {
			this.$root.go('/payment/', {
				orderId: this.prescriptionInfo.orderId,
				type: 1,
				totalFee: this.prescriptionInfo.totalFee,
				appointmentId: this.appointmentId
			});
			// setTimeout(() => {
			// window.location.reload();
			// }, 100);
		},
		toDeliveryAddress () {
			this.$root.go('/delivery-addr', { ...this.$route.query });
		}
	}
};
</script>
<style lang="less" scoped>
	.wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		margin: 0;
		position: relative;
		.order-content {
			flex: 1;
			overflow: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			// padding-bottom: 100px;
		}
		.order-content>.detail {
			// display: flex;
			// flex-direction: column;
			// align-items: center;
			padding: 0 12px;
		}
	}
	.title {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #232830;
	}
	.header {
		position: relative;
		padding-top: 30px;
		display: flex;
		align-items: center;
		width: 100%;
		// margin: 0 12px;
		.h-bg {
			height: 80px;
			background: linear-gradient(134deg, #FF7F4E 0%, #FF6453 100%);
			position: absolute;
			top: 0;
			width: 100%;
			left: 0;
			// right: -12px;
		}
		.user-info {
			height: 140px;
			z-index: 7;
			position: relative;
			flex-direction: column;
			align-items: baseline;
			padding: 20px 39.5px 20px 15px;
			box-sizing: border-box;
			margin: 0 12px;
			.address {
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				display: flex;
				align-items: center;
				i {
					width: 28px;
					height: 14px;
					line-height: 14px;
					display: inline-block;
					border: 0.43px solid #FF6450;
					font-size: 8px;
					color: #FF6450;
					text-align: center;
					margin-right: 5px;
				}
				span {
					font-size: 13px;
					color: #7D7D7D;
				}
			}
			.add-de {
				margin: 12px 0 10px;
				font-size: 17px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #232830;
			}
			.ui {
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #232830;
			}
			.to {
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
				color: #232830;
				font-weight: 600;
				font-size: 14px;
				width: 6px;
				height: 11px;
				background-size: 100%;
				background-image: url('../../assets/images/right-arrow1.png');
			}
		}
	}
	.radius {
		background: #FFFFFF;
		box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
		border-radius: 8px;
		border: .5px solid #EFEFEF;
		width: 100%;
	}
	.mt-8 {
		margin-top: 8px;
	}
	.order {
		height: 40px;
		line-height: 40px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #232830;
		padding-left: 15px;
		// margin: 12px;
		// width: 100%;
		box-sizing: border-box;
	}
	.prescription {
		padding: 15px 10px 18px 12px;
		box-sizing: border-box;
		.top {
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			.left {
				p {
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #232830;
				}
				span {
					font-size: 12px;
					color: #7D7D7D;
					margin-top: 10px;
					display: inline-block;
				}
			}
			.right {
				width: 50px;
				height: 44px;
				border-radius: 2px;
				opacity: 0.56;
				border: 0.5px solid #FF6450;
				color: #FF6450;
				font-size: 12px;
				padding: 5px;
				box-sizing: border-box;
			}
		}
		.bottom {
			background: #F9FAFB;
			padding: 15px 15px 7px 15px;
			margin-top: 20px;
			span {
				min-width: 58px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				display: inline-block;
				margin-bottom: 13px;
				&.mr {
					margin-right: 46px;
				}
				i {
					&:nth-child(1) {
						color: #7D7D7D;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.zd {
		padding: 20px 0 20px 16px;
		box-sizing: border-box;
		div {
			flex-direction: column;
			margin-top: 15px;
			color: #7D7D7D;
			font-size: 14px;
		}
	}
	.kf {
		padding: 20px 0 20px 16px;
		box-sizing: border-box;
		display: flex;
    flex-direction: column;
		p {
			margin-bottom: 18px;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FAA74F;
		}
		.detail {
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #7D7D7D;
			margin: 25px 0;
			word-break: break-word;
		}
	}
	.yz {
		padding: 20px 0 20px 15px;
		box-sizing: border-box;
		p {
			margin-bottom: 15px;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #7D7D7D;
		}
	}
	.fy {
		margin-bottom: 82px;
		padding: 20px 15px 6px 15px;
		box-sizing: border-box;
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #232830;
		}
		i {
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #F33D3A;
			margin-bottom: 14px;
		}
	}
	.pay {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		// right: 0;
		background: #FFFFFF;
		height: 52px;
		padding: 0;
		span {
			font-size: 20px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #F33D3A;
			margin-left: 12px;
		}
		i {
			&:nth-child(2) {
				font-size: 12px;
			}
		}
		.pay-btn {
			margin-right: 12px;
		}
	}
</style>